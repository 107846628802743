import {  takeLatest, select, all, put, delay, call } from 'redux-saga/effects';
import { trackIt } from '@vfde-fe/sails_core';
import { CLASS_NAME_APP, DEFAULT_TRACKING_TOOL_NAME, SET_ACTIVE_PAGE, SET_DEFAULT_STATE } from './constant';
import { selectAllPages, selectPreviousPage } from './selector';
import { txtNormalize } from './util/tracking-util';
import { setDefaultStateFailed, setDefaultStateSuccess } from './actions';

/**
 * getDefaultContactInfo
 * Reads the default contant info from a variable that lives in
 * the indow['VF'].SAILS.flyout namespace
 * This variable is set by simplicity (hotlines-templates-controller.js)
 */

export function* getDefaultContactInfo (args) {
    const tryForSeconds = 10;
    let defaultContactInfo;
    let error;

    if (!args.counter) {
        args.counter = 0;
    }

    try {
        defaultContactInfo = window['VF'].SAILS.flyout.defaultContactInfo;
    } catch (e) {
        defaultContactInfo = false;
        error = e;
    }

    if (defaultContactInfo) {
        yield put (setDefaultStateSuccess(defaultContactInfo));

        return;
    }

    yield delay(100);
    args.counter ++;

    if (args.counter < tryForSeconds * 10) {
        yield call (getDefaultContactInfo, args);
    } else {
        yield put (setDefaultStateFailed({
            seviourError: true,
            headline: 'FLYOUT :: Default Contact Info not found',
            content: error.toString(),
        }));
    }
}

declare var mtls: any;
/**
 * setTrackingToolSaga
 */
export function* setTrackingToolSaga (args) {

    if (!args.activePage) {
        return;
    }

    const [previousPage, allPages] = yield all([
        select(selectPreviousPage()),
        select(selectAllPages()),
    ]);

    // matelso
    if ( !previousPage  && typeof mtls !==  'undefined' && mtls.loaded ) {
        // mtls.scan(document.getElementsByName(CLASS_NAME_APP)[0]);
        mtls.scan();
    }

    let  tool = allPages.find(element => element.id === args.activePage).trackingName;
    tool = tool ? tool : DEFAULT_TRACKING_TOOL_NAME;

    // const variant = (previousPage === allPages[allPages.length - 1].id) ? 'reactive' :  'proactive';
    const variant = 'reactive';

    tool = txtNormalize(tool);

    const tools = [];
    allPages.forEach(element =>  { if (element.trackingName) {tools.push(element.trackingName); }});

    trackIt('flyout', {
        action: 'click',
        tool,
        variant,
        tools,
    });

 }
/**
 * Export generator function
 */
 export default function* raSaga () {
    // JARVIS
    yield takeLatest(SET_DEFAULT_STATE, getDefaultContactInfo);
    yield takeLatest(SET_ACTIVE_PAGE, setTrackingToolSaga);
}

import {
    CLASS_NAME_APP,
    CLASS_NAME_FEW_ITEMS,
    FLYOUT_BOTTOM_POS,
    MAX_MOBILE_WIDTH,
    BORDER_RADIUS,
} from '../constant';
import { checkIfMobileViewport } from './flyout-util';

/**
 * stack buttons
 * handels the stacking display logic of the menu buttons
 */
export const stackButtons = (collection: HTMLCollectionOf<Element>, shaddowElement: HTMLElement) => {
    const buttonArray = Array.from (collection).reverse();
    const fewItems = document.getElementsByClassName(CLASS_NAME_APP)[0].classList.contains(CLASS_NAME_FEW_ITEMS);
    let buttonHeight = 0;

    buttonArray.forEach( (element, index) => {
        const button = element as HTMLElement;

        buttonHeight = button.offsetHeight;

        // vertical positioning of the button
        let pos;

        if (checkIfMobileViewport()) {
            pos = FLYOUT_BOTTOM_POS;
        } else {
            pos = !fewItems  ? index * buttonHeight + FLYOUT_BOTTOM_POS : (index * buttonHeight + FLYOUT_BOTTOM_POS) - buttonHeight;
        }

        button.style.bottom = `${pos}px`;
    });

    const firstButton = buttonArray[buttonArray.length - 1] as HTMLElement;
    firstButton.style.borderTopLeftRadius = BORDER_RADIUS;

    // set the height of the shaddow div correctly
    if (fewItems) {
        const lastButton = buttonArray[1] as HTMLElement;
        lastButton.style.borderBottomLeftRadius = BORDER_RADIUS;
        shaddowElement.style.height = `${buttonHeight * (buttonArray.length - 1) }px`;

    } else {
        shaddowElement.style.height = `${buttonHeight * buttonArray.length}px`;
    }

    if (checkIfMobileViewport()) {
        shaddowElement.style.height = `${buttonHeight}px`;
    }

};

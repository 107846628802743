import { ATTRIBUTE_SHOW_BUSSINESS_HOURS, ATTRIBUTE_SHOW_CALL_BUTTON, CLASS_NAME_CONTENT_DEFAULT, CLASS_NAME_CONTENT_MOBILE, PROP_BUSINESS_HOURS, PROP_NUMBER_DISPLAY, PROP_NUMBER_LINK } from '../constant';
import { DefaultContactInfo } from '../types';

export default (containerToAdd: HTMLElement, defaultContactInfo: DefaultContactInfo) => {
    const showBusinessHours = containerToAdd.getAttribute(ATTRIBUTE_SHOW_BUSSINESS_HOURS);
    const showContactButton = containerToAdd.getAttribute(ATTRIBUTE_SHOW_CALL_BUTTON);

    if (showBusinessHours && showBusinessHours === 'true') {
        const p = document.createElement('p');
        p.classList.add('brix-text');
        p.classList.add('brix-spacing-100');
        p.innerHTML = defaultContactInfo[PROP_BUSINESS_HOURS];

        addToDiv(containerToAdd, p);
    }

    if (showContactButton && showContactButton === 'true') {

        const anchor = document.createElement('a');

        anchor.classList.add('brix-button');
        anchor.classList.add('brix-button--strong');
        anchor.classList.add('brix-button--rectangle');
        anchor.classList.add('brix-button--large');
        anchor.classList.add('brix-button--fullwidth');

        // remove first 0 because we want to add +49
        const linkNumber = defaultContactInfo[PROP_NUMBER_LINK]; // .replace(/^0+/, '');

        // anchor.setAttribute('href', `tel:+49${linkNumber}`);
        anchor.setAttribute('href', `tel:${linkNumber}`);
        anchor.setAttribute('data-tracking-name', `['hotline cta',{'number':'${linkNumber}'}]`);

        anchor.innerHTML = `
        <span class="brix-button__text">
        ${defaultContactInfo[PROP_NUMBER_DISPLAY]}
        </span>`;

        anchor.tabIndex = 0;

        addToDiv(containerToAdd, anchor);
    }
};

const addToDiv = (container: HTMLElement, elementToAdd: HTMLElement) => {
    container.getElementsByClassName(CLASS_NAME_CONTENT_DEFAULT)[0].appendChild(elementToAdd);

    // also add it to the mobile container if there is one
    if (container.getElementsByClassName(CLASS_NAME_CONTENT_MOBILE).length > 0) {
        container.getElementsByClassName(CLASS_NAME_CONTENT_MOBILE)[0].appendChild(elementToAdd.cloneNode(true));
    }
};

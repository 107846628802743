// polyfill for cypress tests...
import 'whatwg-fetch';
import './style.scss';
import { configStore } from '@vfde-fe/sails_core';
import connectedFlyout from './container/Flyout/index';

const store = configStore({});

if (store) {
    connectedFlyout();
}

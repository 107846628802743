import {
    CLASS_MODIFIER_ACTIVE,
    CLASS_MODIFIER_VISIBLE,
    CLASS_NAME_CLOSE_BUTTON,
    CLASS_NAME_CONTENT_CONTAINER,
    CLASS_NAME_CONTENT_DESKTOP,
    CLASS_NAME_DRAWER,
    CLASS_NAME_OPTION_BUTTON,
} from '../constant';
import { checkIfMobileViewport } from './flyout-util';

/**
 * If the overlay is open the user only should loop within the overly
 * this is what the tab loop manager is for
 */
export default (htmlElement: HTMLElement, fewItemMode: boolean) => {
    let shiftKeyDown = false;

    document.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.code === 'ShiftLeft') {
            shiftKeyDown = true;
        }
    });

    document.addEventListener('keyup', (e: KeyboardEvent) => {
        if (e.code === 'ShiftLeft') {
            shiftKeyDown = false;
        }
    });

    /**
     * CLOSE BUTTONS - DIRECTION FORMWARD only
     */
    const allCloseButtons = htmlElement.getElementsByClassName(CLASS_NAME_CLOSE_BUTTON);

    // close buttons
    Array.from(allCloseButtons).forEach((closeButton, index) => {

        closeButton.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.code !== 'Tab' || shiftKeyDown) {
                return;
            }

            let elementToFocus;

            if (!checkIfMobileViewport()) {
                // on desktop only the last close button is relevant - in few items mode the last close button is not visible on desktop
                if (index !== allCloseButtons.length - (fewItemMode ? 2 : 1)) {
                    return;
                }
                e.preventDefault();
                // focus the very first element of htmlElement
                elementToFocus = getFistSelectableElement(htmlElement);
            } else {
                e.preventDefault();
                elementToFocus = getFistSelectableElement(
                    closeButton.parentElement,
                );
            }
            elementToFocus.focus();
        });
    });

    /**
     * MENU BUTTONS
     */
    const allMenuButtons = htmlElement.getElementsByClassName(CLASS_NAME_OPTION_BUTTON );
    Array.from(allMenuButtons).forEach((menuButton, index) => {
        menuButton.addEventListener('keydown', (e: KeyboardEvent) => {

            const menuVisible = htmlElement.getElementsByClassName(CLASS_NAME_DRAWER)[0].classList.contains(CLASS_NAME_DRAWER + CLASS_MODIFIER_VISIBLE);
            // only desktop is relevant if menu is open
            if (e.code !== 'Tab' || checkIfMobileViewport() || !menuVisible) {
                return;
            }

            // - DIRECTION FORMWARD
            if (!shiftKeyDown) {

                // if it is not the last button return
                if (index !== allMenuButtons.length - (fewItemMode ? 2 : 1)) {
                    return;
                }

                e.preventDefault();
                getFistSelectableElement(htmlElement).focus();
            }
            // - DIRECTION BACKWARDS
            else {
                // for the first elemet

                if (index === 0) {
                    // get the last menu element
                    e.preventDefault();
                    const lastElement = getLastSelectableElement(htmlElement);

                    if (
                        lastElement.parentElement.parentElement.parentElement.classList.contains(CLASS_NAME_CONTENT_CONTAINER + CLASS_MODIFIER_ACTIVE)) {
                        lastElement.focus();
                    } else {
                        if (!fewItemMode) {
                            const lastMenuButton = allMenuButtons[ allMenuButtons.length - 1 ] as HTMLElement;
                            lastMenuButton.focus();
                        } else {
                            // in few mode it is always the second element
                            const secondContainer = htmlElement.getElementsByClassName(CLASS_NAME_CONTENT_CONTAINER)[1] as HTMLElement;
                            getLastSelectableElement(secondContainer).focus();
                        }
                    }
                }
            }
        });
    });

    // FIRST SELECTABLE ITEM

    Array.from(htmlElement.getElementsByClassName(CLASS_NAME_CONTENT_CONTAINER)).forEach((elem, index) => {

        const firstSelectableItemOfElement = getFistSelectableElement(elem as HTMLElement, true);

        firstSelectableItemOfElement.addEventListener('keydown', (e: KeyboardEvent) => {

            // only backwards is relevant
            if (e.code !== 'Tab' || !shiftKeyDown) {
                return;
            }

            if (!checkIfMobileViewport()) {
                // for desktop only the first of all elements is relevant
                if (index === 0) {

                    if (!fewItemMode) {
                        const lastMenuButton = allMenuButtons[ allMenuButtons.length - 1] as HTMLElement;
                        e.preventDefault();
                        lastMenuButton.focus();
                    } else {
                        // it is always the second element
                        const secondContainer = htmlElement.getElementsByClassName(CLASS_NAME_CONTENT_CONTAINER)[1] as HTMLElement;

                        e.preventDefault();
                        getFistSelectableElement(secondContainer).focus();
                    }
                }
            } else {
                const activeElement = htmlElement.getElementsByClassName(
                    CLASS_NAME_CONTENT_CONTAINER + CLASS_MODIFIER_ACTIVE,
                )[0] as HTMLElement;
                e.preventDefault();
                getLastSelectableElement(activeElement).focus();
            }
        });
    });
};

/**
 * Remove the deskopt only content from the array in mobile viewport
 */
export const removeInvisibleForMobileViewport = (arr: Array<HTMLElement>) => {
    return arr.filter(elm => {
        if (checkIfMobileViewport()) {
            return !elm.parentElement.classList.contains(CLASS_NAME_CONTENT_DESKTOP);
        } else {
            return true;
        }
    });
};

/**
 * Helper function for above
 *
 * @param htmlElement - elemt to query
 * @param useSecondButtonElement - if set to true second button will be used instead of the first elemet
 * @returns - the element that comes first in the dom
 */

export const getFistSelectableElement = (
    htmlElement: HTMLElement,
    useSecondButtonElement = false,
) => {
    const allButtons = removeInvisibleForMobileViewport(
        Array.from(htmlElement.getElementsByTagName('button')),
    );
    const allLinks = removeInvisibleForMobileViewport(
        Array.from(htmlElement.getElementsByTagName('a')),
    );

    let firstButton = !useSecondButtonElement ? allButtons[0] : allButtons[1];

    if (firstButton.getAttribute('disabled')) {
        firstButton = !useSecondButtonElement ? allButtons[1] : allButtons[2];
    }

    const firstAnchor = allLinks[0];

    if (!firstAnchor && !firstButton) {
        return;
    } else if (firstAnchor && !firstButton) {
        return firstAnchor;
    } else if (!firstAnchor && firstButton) {
        return firstButton;
    }

    // none of the above - see what element comes first
    const positionOFButton = firstAnchor.compareDocumentPosition(firstButton);

    if (positionOFButton === Node.DOCUMENT_POSITION_FOLLOWING) {
        return firstAnchor;
    } else {
        return firstButton;
    }
};

/**
 * getLastSelectableElement
 * @param htmlElement
 * @returns
 */
 export const getLastSelectableElement = (htmlElement: HTMLElement) => {
    const allButtons = removeInvisibleForMobileViewport(
        Array.from(htmlElement.getElementsByTagName('button')),
    );
    const allLinks = removeInvisibleForMobileViewport(
        Array.from(htmlElement.getElementsByTagName('a')),
    );

    const lastButton = allButtons[allButtons.length - 1];
    const lastAnchor = allLinks[allLinks.length - 1];

    if (!lastAnchor && !lastButton) {
        return;
    } else if (lastAnchor && !lastButton) {
        return lastAnchor;
    } else if (!lastAnchor && lastButton) {
        return lastButton;
    }

    // none of the above - see what element comes first
    const positionOFButton = lastAnchor.compareDocumentPosition(lastButton);

    if (lastAnchor && positionOFButton === Node.DOCUMENT_POSITION_PRECEDING) {
        return lastAnchor;
    } else {
        return lastButton;
    }
};

import { connect, injectReducer, injectSaga} from '@vfde-fe/sails_core';
import {createStructuredSelector} from 'reselect';
import { trackIt } from '@vfde-fe/sails_core';
import { NAMESPACE } from '../../constant';
import {
    CLASS_NAME_APP,
    CLASS_NAME_FEW_ITEMS,
    CLASS_NAME_CONTENT_CONTAINER,
    CLASS_NAME_OPTION_BUTTON,
    CLASS_NAME_ALL_OPTIONS_BUTTON,
    CLASS_NAME_CLOSE_BUTTON,
    CLASS_NAME_CONTENT_DEFAULT,
    CLASS_NAME_CONTENT_MOBILE,
    CLASS_NAME_CONTENT_DESKTOP,
    CLASS_NAME_BUTTONS_SHADDOW,
    CLASS_NAME_COVER,
    CLASS_NAME_DRAWER,
    MAX_NUMBER_FEW_ITEMS_MODE,
    TRANSITION_DURATION,
    CLASS_MODIFIER_VISIBLE,
    DATA_FIELD_TRACKING,
    DEFAULT_TRACKING_TOOL_NAME,
} from './constant';
import { setDefaultState, setActivePage, addPage } from './actions';
import flyoutReducer from './reducer';
import { selectActivePage, selectDefaultContactInfo, selectError } from './selector';
import { focusPage, blurPage, showOverlay, hideOverlay, checkIfMobileViewport} from './util/flyout-util';
import { addEditorialTracking } from './util/tracking-util';
import { stackButtons } from './util/buttons-util';
import flyoutSaga from './saga';
import tabLoop from './util/tab-loop-manager';
import mountAllOptionsPage from './patterns/AllOptionsPage';
import mountContactButton from './patterns/BusinessHoursAndContactButton';
import { DefaultContactInfo, FlyoutError } from './types';

const flyout =  (state, action) => {

    let appInitialized = false;
    let autoFocus = false;

    injectSaga(NAMESPACE, flyoutSaga);
    injectReducer( NAMESPACE,  flyoutReducer);

    // fire default state
    action.setDefaultState();

    //
    const app = document.getElementsByClassName(CLASS_NAME_APP)[0] as HTMLElement;

    // button handlers - navigation buttons
    const containers = Array.from(document.getElementsByClassName(CLASS_NAME_CONTENT_CONTAINER));

    containers.forEach((container: HTMLElement, index) => {
        // navi button - first button element by design
        const naviButton =  container.getElementsByTagName('button')[0];
        naviButton.addEventListener('click', () => action.setActivePage(container.id));
        // add keyboard handler for tab navigation
        naviButton.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.code === 'Space' || e.code === 'Enter') {
                autoFocus = true;
            }
        });

        // all options button (optional)
        const allOptionsButton = container.getElementsByClassName(CLASS_NAME_ALL_OPTIONS_BUTTON)[0];
        const menuContainerID = containers[containers.length - 1].id;
        allOptionsButton?.addEventListener('click', () => action.setActivePage(menuContainerID));
        // add keyboard handler for tab navigation
        allOptionsButton?.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.code === 'Space' || e.code === 'Enter') {
                autoFocus = true;
            }
        });

        // close buttons
        container.getElementsByClassName(CLASS_NAME_CLOSE_BUTTON)[0].addEventListener('click', () => action.setActivePage(false));

        // add desktop class to content container if mobile content is present
        if (container.getElementsByClassName(CLASS_NAME_CONTENT_MOBILE).length > 0) {
            container.getElementsByClassName(CLASS_NAME_CONTENT_DEFAULT)[0].classList.add(CLASS_NAME_CONTENT_DESKTOP);
        }

        // add the page to the store
        if (index < containers.length - 1) {
            action.addPage(container.id, container.getAttribute(DATA_FIELD_TRACKING));
        } else {
            action.addPage(container.id, DEFAULT_TRACKING_TOOL_NAME);
        }

    });

    // add the few_items class if applicable
    if (containers.length <= MAX_NUMBER_FEW_ITEMS_MODE) {
        document.getElementsByClassName(CLASS_NAME_APP)[0].classList.add(CLASS_NAME_FEW_ITEMS);
    }

    // stack primary navigation buttons
    const stackIt = () => {
        stackButtons(document.getElementsByClassName(CLASS_NAME_OPTION_BUTTON), document.getElementsByClassName(CLASS_NAME_BUTTONS_SHADDOW)[0] as HTMLElement);
    };
    stackIt();
    window.addEventListener('resize', () => stackIt());

    // cover click handler
    const cover  = document.getElementsByClassName(CLASS_NAME_COVER)[0] as HTMLElement;
    cover.addEventListener('click', () => action.setActivePage(false));

    // drawer reference
    const drawer = document.getElementsByClassName(CLASS_NAME_DRAWER)[0] as HTMLElement;

    // All options page
    mountAllOptionsPage(containers, (page: string, af = false) => {
        autoFocus = af;
        action.setActivePage(page);
    });

    /////////////////////////////////////////

    //  Handler state changes
    return {
        getDerivedStateFromProps ({activePage, defaultContactInfo, error}, oldState) {

            if (defaultContactInfo && !appInitialized) {
                appInitialized = true;

                // make the app visble
                app.classList.add(CLASS_NAME_APP + CLASS_MODIFIER_VISIBLE);

                // add contact button
                containers.forEach((container: HTMLElement, index) => {

                    if (true && index < 3) {
                        mountContactButton(container, defaultContactInfo);
                    }
                    // add editorial trakcing
                    addEditorialTracking(container, trackIt);
                });

                    // add tab loop manager
                    tabLoop(document.getElementsByClassName(CLASS_NAME_APP)[0] as HTMLElement, containers.length <= MAX_NUMBER_FEW_ITEMS_MODE);
            }

            if (error) {
                app.style.display = 'none';
                console.log(`%c${error.headline}`, 'color:green; font-size:12px');
                console.log(`%c${error.content}`, 'color:green; font-size:10px');
            }

            if (activePage) {
                // cover with fix needed for animation
                cover.style.width = '';
                cover.style.height = '';

                showOverlay(cover, drawer);

                // hide previous page (if applicable)
                if (oldState.activePage) {
                    blurPage(oldState.activePage);
                }
                // show current page
                focusPage(activePage, autoFocus);
                autoFocus = false;

                document.body.style.position = 'fixed';
                document.body.style.width = '100%' ;

                window.scrollTo(0, 0);

            } else {

                hideOverlay(cover, drawer);

                // hide previous page (if applicable) after transition
                if (oldState.activePage) {
                    setTimeout(() => {
                        // cover with fix needed for animation
                        cover.style.width = '0';
                        cover.style.height =  '0';
                        document.body.style.position = '';
                        document.body.style.width = '';

                        blurPage(oldState.activePage);
                        window.scrollTo(0, 0);
                    }, TRANSITION_DURATION);
                }
            }
        },
    };
};

interface StateProps {
    /**
     * activePage
     */
    activePage: boolean | string;
    /**
     * defaultContactInfo
     */
    defaultContactInfo: boolean | DefaultContactInfo;
    /**
     * defaultContactInfo
     */
    error: boolean | FlyoutError;
}

/** State Mapping */
const mapStateToProps = createStructuredSelector<undefined, StateProps>({
    activePage: selectActivePage(),
    defaultContactInfo: selectDefaultContactInfo(),
    error: selectError(),

});
const mapDispatchToProps = {
    setDefaultState,
    setActivePage,
    addPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(flyout);

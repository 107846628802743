import {
    CLASS_NAME_COVER,
    CLASS_NAME_DRAWER,
    CLASS_MODIFIER_VISIBLE,
    CLASS_MODIFIER_ACTIVE,
    CLASS_NAME_CONTENT_CONTAINER,
    MAX_MOBILE_WIDTH,
    CLASS_NAME_ALL_OPTIONS_BUTTON,
    CLASS_MODIFIER_ANIMATED,
 } from '../constant';

/**
 * checkIfBurgerViewport
 */
export const checkIfMobileViewport = () => window.innerWidth <= MAX_MOBILE_WIDTH;

/**
 * showOverlay
 */
export const showOverlay = (cover: HTMLElement, drawer: HTMLElement) => {
    cover.classList.add(`${CLASS_NAME_COVER}${CLASS_MODIFIER_VISIBLE}`);
    cover.classList.add(`${CLASS_NAME_COVER}${CLASS_MODIFIER_ANIMATED}`);
    drawer.classList.add(`${CLASS_NAME_DRAWER}${CLASS_MODIFIER_VISIBLE}`);

};
/**
 * hideOverlay
 */
 export const hideOverlay = (cover: HTMLElement, drawer: HTMLElement) => {
    cover.classList.remove(`${CLASS_NAME_COVER}${CLASS_MODIFIER_VISIBLE}`);
    drawer.classList.remove(`${CLASS_NAME_DRAWER}${CLASS_MODIFIER_VISIBLE}`);
};
/**
 * focusPage
 *  if autofocus is set to true the first anchor or button element will receive focus
 */
export const focusPage = (pageID: string, autoFocus = false) => {
    const activePage = document.getElementById(pageID);

    activePage.classList.add(`${CLASS_NAME_CONTENT_CONTAINER}${CLASS_MODIFIER_ACTIVE}`);
    activePage.getElementsByTagName('button')[0].setAttribute('disabled', 'true');
    activePage.getElementsByTagName('button')[0].blur();

    if (autoFocus) {
        let links;
        let buttons;
        let linkToFocus;
        let buttonToFocus;
        const mvp = checkIfMobileViewport();
        // check if on mobile viewport and if mobile content exsits
        if (mvp && activePage.getElementsByClassName('contact-flyout__content-mobile').length > 0) {

            const mobileSpan = activePage.getElementsByClassName('contact-flyout__content-mobile')[0];
            links = mobileSpan.getElementsByTagName('a');
            buttons = mobileSpan.getElementsByTagName('button');
            if (buttons.length === 0) {
                const allOptionsButton = activePage.getElementsByClassName(CLASS_NAME_ALL_OPTIONS_BUTTON)[0];
                buttons = [allOptionsButton];

               linkToFocus = links[0] as HTMLAnchorElement;
               buttonToFocus = buttons[0] as HTMLButtonElement;
            }
        } else {
            links = activePage.getElementsByTagName('a');
            buttons = activePage.getElementsByTagName('button');
            linkToFocus = links[0] as HTMLAnchorElement;
            buttonToFocus = buttons[1] as HTMLButtonElement;
        }

        // the first button (nav link) does not count

        // const buttonToFocus = buttons[1] as HTMLButtonElement;

        // if there is only a link
        if (linkToFocus && buttons.length === 2) {
            linkToFocus.focus();
        } else if (!linkToFocus) { // if ther are links and not buttons beseides menu and close
            buttonToFocus.focus();
        } else { // links
            const positionOFButton = linkToFocus.compareDocumentPosition(buttonToFocus);
            // if button is after link focus link otherwise focus button
            positionOFButton === Node.DOCUMENT_POSITION_FOLLOWING ?  linkToFocus.focus() : buttonToFocus.focus();
        }
    }
};
/**
 * blurPage
 */
export const blurPage = (pageID: string) => {
    const notAnymoreActivePage = document.getElementById(pageID);
    notAnymoreActivePage.classList.remove(`${CLASS_NAME_CONTENT_CONTAINER}${CLASS_MODIFIER_ACTIVE}`);
    notAnymoreActivePage.querySelector('button').removeAttribute('disabled');
};

import { NAMESPACE } from '../../constant';

/** SET_DEFAULT_STATE */
export const SET_DEFAULT_STATE = `${ NAMESPACE }/SET_DEFAULT_STATE`;

/** SET_PAGE_ACTIVE */
export const SET_ACTIVE_PAGE = `${ NAMESPACE }/SET_ACTIVE_PAGE`;

/** ADD_PAGE */
export const ADD_PAGE = `${ NAMESPACE }/ADD_PAGE`;

/** SET_DEFAULT_STATE_SUCCESS */
export const SET_DEFAULT_STATE_SUCCESS = `${ NAMESPACE }/SET_DEFAULT_STATE_SUCCESS`;

/** SET_DEFAULT_STATE_FAILED */
export const SET_DEFAULT_STATE_FAILED = `${ NAMESPACE }/SET_DEFAULT_STATE_FAILED`;

/**
 * MAX_NUMBER_FEW_ITEMS_MODE
 */
export const MAX_NUMBER_FEW_ITEMS_MODE = 3;
/**
 * TRANSITION_DURATION
 */
export const TRANSITION_DURATION = 500;

///////////////////////////////////////////////// IDs ///////////////////////////////////////////
/**
 * ID_DYNAMIC_BUTTON_LIST
 */
export const ID_DYNAMIC_BUTTON_LIST = 'cf-dynamic-button-list';

///////////////////////////////////////////////// DEFAULT CONTACT INFO ///////////////////////////////////////////
/**
 * SAILS_NAMESPACE
 */
 export const SAILS_NAMESPACE = 'SAILS';
/**
 * PROP_NUMBER_DISPLAY
 */
export const PROP_NUMBER_DISPLAY = 'mobileCallNumber';
/**
 * PROP_NUMBER_DISPLAY
 */
 export const PROP_NUMBER_LINK = 'mobileCallNumberPrepared';
/**
 * PROP_BUSINESS_HOURS
 */
 export const PROP_BUSINESS_HOURS = 'mobileCallTimes';

///////////////////////////////////////////////// ATTRIBUTE NAMES ///////////////////////////////////////////
/**
 * ATTRUBUTE_BUTTON_LABEL
 */
export const ATTRIBUTE_BUTTON_LABEL = 'data-button-label';
/**
 * ATTRUBUTE_BUTTON_LABEL
 */
export const ATTRIBUTE_BUTTON_ICON = 'data-button-icon';
/**
 * ATTRUBUTE_SHOW_CALL_BUTTON
 */
export const ATTRIBUTE_SHOW_CALL_BUTTON = 'data-show-default-button';
/**
 * ATTRUBUTE_SHOW_CALL_BUTTON
 */
 export const ATTRIBUTE_SHOW_BUSSINESS_HOURS = 'data-show-default-hours';

///////////////////////////////////////////////// CSS CLASS NAMES ///////////////////////////////////////////
/**
 * CLASS NAME APP
 */
export const CLASS_NAME_APP = 'contact-flyout';
/**
 * CLASS NAME CONTENT_CONTAINER
 */
export const CLASS_NAME_CONTENT_CONTAINER = 'contact-flyout__container';
/**
 * CLASS NAME ALL OPTIONS BUTTON
 */
 export const CLASS_NAME_OPTION_BUTTON = 'contact-flyout__button';
/**
 * CLASS NAME ALL OPTIONS BUTTON
 */
export const CLASS_NAME_ALL_OPTIONS_BUTTON = 'contact-flyout__button-all-contact-options';
/**
 * CLASS NAME ALL OPTIONS BUTTON
 */
export const CLASS_NAME_BUTTONS_SHADDOW = 'contact-flyout__button-shaddow';
/**
 * CLASS NAME CLOSE_BUTTON
 */
export const CLASS_NAME_CLOSE_BUTTON = 'contact-flyout__close-button';
/**
 * CLASS NAME CLOSE_BUTTON
 */
export const CLASS_NAME_CONTENT_MOBILE = 'contact-flyout__content-mobile';
/**
 * CLASS NAME CLOSE_BUTTON
 */
export const CLASS_NAME_CONTENT_DESKTOP = 'contact-flyout__content-desktop';
/**
 * CLASS NAME CLOSE_BUTTON
 */
export const CLASS_NAME_CONTENT_DEFAULT = 'contact-flyout__content-default';
/**
 * CLASS NAME FEW ITEMS
 */
export const CLASS_NAME_FEW_ITEMS = 'contact-flyout--few-items';
/**
 * CLASS NAME COVER
 */
 export const CLASS_NAME_COVER = 'contact-flyout__cover';
/**
 * CLASS NAME COVER
 */
export const CLASS_NAME_DRAWER = 'contact-flyout__drawer';

/**
 * CLASS MODIFIER VISIBLE
 */
export const CLASS_MODIFIER_VISIBLE = '--visible';
/**
 * CLASS_BRIX_NO_SCROLL
 */
 export const CLASS_BRIX_NO_SCROLL = 'brix-no-scroll';
/**
 * CLASS MODIFIER ACTIVE
 */
export const CLASS_MODIFIER_ACTIVE = '--active';
/**
 * CLASS MODIFIER ACTIVE
 */
 export const CLASS_MODIFIER_ANIMATED = '--animated';

///////////////////////////////////////////////// TRACKING ///////////////////////////////////////////
/**
 * DATA_FIELD_TRACKING
 */
export const DATA_FIELD_TRACKING = 'data-tracking-name';
/**
 * DATA_FIELD_TRACKING
 */
export const DEFAULT_TRACKING_TOOL_NAME = 'flyout';

///////////////////////////////////////////////// LAYOUT ///////////////////////////////////////////
 /**
  * FLYOUT_BOTTOM_POS
  */
export const FLYOUT_BOTTOM_POS = 96;
/**
 * MAX_MOBILE_WIDTH
 */
 export const MAX_MOBILE_WIDTH = 599;
 /**
  * BORDER_RADIUS
  */
export const BORDER_RADIUS = '3px';

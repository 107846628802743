import {
    DATA_FIELD_TRACKING,
} from '../constant';

/**
 * add editorial tracking
 */
 export const addEditorialTracking = (containerDiv: Element, trackingFunction) => {
    // enabled buttons -- non query selector version
    const nodeList = containerDiv.getElementsByTagName('*');
    for (let i = 1; i < nodeList.length; i++) {
        const elm = nodeList[i];
        if (elm.getAttribute(DATA_FIELD_TRACKING)) {
            elm.addEventListener('click', () => {
                const myTrackingString = elm.getAttribute(DATA_FIELD_TRACKING);
                const formatToJsonString = txtNormalize(myTrackingString.replace(/'/gi, '"'));
                const trackingJson = JSON.parse(formatToJsonString);

                // replace number in tracking json in case this is not caught by matelso
                if (trackingJson[1].number && elm.getAttribute('href').indexOf('tel:') !== -1) {
                    const num = elm.getAttribute('href').replace('tel:', '');
                    trackingJson[1].number = elm.getAttribute('href').replace('tel:', '');
                }

                trackingFunction(trackingJson[0], trackingJson[1]);
            });
        }
    }
};

/**
 * txtNormalize
 * that should be moved somewhere else
 */
 export const txtNormalize = (txtInput: string): string => {
    txtInput = txtInput.replace(/ä/g, 'ae');
    txtInput = txtInput.replace(/Ä/g, 'Ae');
    txtInput = txtInput.replace(/ö/g, 'oe');
    txtInput = txtInput.replace(/Ö/g, 'Oe');
    txtInput = txtInput.replace(/ü/g, 'ue');
    txtInput = txtInput.replace(/Ü/g, 'Ue');
    txtInput = txtInput.replace(/ß/g, 'ss');

    return txtInput;
};

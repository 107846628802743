import {
    SET_DEFAULT_STATE,
    SET_DEFAULT_STATE_SUCCESS,
    SET_DEFAULT_STATE_FAILED,
    SET_ACTIVE_PAGE,
    ADD_PAGE,
} from './constant';
import { DefaultContactInfo, FlyoutError, PageVO } from './types';

/**
 * sets Default State
 */
export const setDefaultState = () => ({
    type: SET_DEFAULT_STATE,
});
/**
 * setDefaultStateSuccess
 */
 export const setDefaultStateSuccess = (defaultContactInfo: DefaultContactInfo) => ({
    type: SET_DEFAULT_STATE_SUCCESS,
    defaultContactInfo,
});
/**
 * setDefaultStateFailed
 */
export const setDefaultStateFailed = (error: FlyoutError) => ({
    type: SET_DEFAULT_STATE_FAILED,
    error,
});
/**
 * setPageActive
 */
export const setActivePage = (activePage: string) => ({
    type: SET_ACTIVE_PAGE,
    activePage,
});

/**
 * setPageActive
 */
 export const addPage = (id: string, trackingName: string) => ({
    type: ADD_PAGE,
    id,
    trackingName,
});

import { ATTRIBUTE_BUTTON_ICON, ATTRIBUTE_BUTTON_LABEL, ID_DYNAMIC_BUTTON_LIST } from '../constant';

export default (containers: Array<Element>, clickHandler) => {

    containers.forEach((container, index) => {
       if (index > containers.length - 2 ) {
           return;
       }

        const id = container.getAttribute('id');
        const label = decodeURI(container.getAttribute(ATTRIBUTE_BUTTON_LABEL));
        const iconURl = decodeURI(container.getAttribute(ATTRIBUTE_BUTTON_ICON));

        const button = document.createElement('a');
        button.classList.add('brix-button-tiles__button');
        button.innerHTML = `
        <img src="${iconURl}">
        <span class="brix-button-tiles__txt">${label}</span>`;

        button.tabIndex = 0;

        button.addEventListener('click', () => clickHandler(id));

        button.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.code ===  'Enter' || e.code === 'Space') {
                clickHandler(id, true);
            }
        });

        document.getElementById(ID_DYNAMIC_BUTTON_LIST).appendChild(button);
   });

};

import { createSelector } from 'reselect';
import { NAMESPACE } from '../../constant';
import { CLASS_NAME_APP, DATA_FIELD_TRACKING, DEFAULT_TRACKING_TOOL_NAME } from './constant';
import { initialState } from './reducer';

const selectState = (state) => state[NAMESPACE] || initialState;
/**
 * selectPageActive
 */
 export const selectDefaultContactInfo = () =>
    createSelector(selectState, state => state.defaultContactInfo);

/**
 * selectPageActive
 */
export const selectActivePage = () =>
    createSelector(selectState, state => state.activePage);

/**
 * selectError
 */
 export const selectError = () =>
    createSelector(selectState, state => state.error);

/**
 * selectAllPages
 */
 export const selectAllPages = () =>
    createSelector(selectState, state => state.allPages);

/**
 * selectPreviousPage
 */
 export const selectPreviousPage = () =>
 createSelector(selectState, state => state.previousPage);

import produce from 'immer';
import { SET_DEFAULT_STATE, SET_ACTIVE_PAGE, SET_DEFAULT_STATE_SUCCESS, SET_DEFAULT_STATE_FAILED, ADD_PAGE } from './constant';
import { FlyoutError, PageVO } from './types';

/**
 * Reducer
 */
export default (state: IFlyoutState = initialState, action ) =>
    produce(state, (draft: IFlyoutState) => {
        switch (action.type) {

            case SET_DEFAULT_STATE:

            break;

            case SET_DEFAULT_STATE_SUCCESS:
                draft.defaultContactInfo = action.defaultContactInfo;
            break;

            case SET_DEFAULT_STATE_FAILED:
                draft.error = action.error;
            break;

            case SET_ACTIVE_PAGE:
                draft.previousPage = state.activePage;
                draft.activePage = action.activePage;
            break;

            case ADD_PAGE:
                draft.allPages = !state.allPages ? new Array<PageVO>() : [...state.allPages as Array<PageVO> ];
                draft.allPages.push({id: action.id, trackingName: action.trackingName});
            break;
        }
    });

/**
 * initialState
 */
export const initialState: IFlyoutState = {
    activePage: false,
    previousPage: false,
    allPages: false,
    defaultContactInfo: false,
    error: false,
};

/**
 * IRoamingAdvisorState
 */
 export interface IFlyoutState {
    /**
     * pageActive
     */
    activePage: boolean | string;
    /**
     * defaultContactInfo
     */
    defaultContactInfo: boolean | object;
    /**
     * defaultContactInfo
     */
    error: boolean | FlyoutError;
     /**
      * previousPage
      */
    previousPage: boolean | string;
    /**
     * allPages
     */
    allPages: boolean | Array<PageVO>;
 }
